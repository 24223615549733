import { HTMLAttributes } from 'react';
import { useParams } from 'next/navigation';
import clsx from 'clsx';
import { format } from 'date-fns';
import { Cart, Order, ShippingMethod } from 'shared/types/cart';
import { CurrencyHelpers } from '~/helpers/currencyHelpers';
import { useFormat } from '~/helpers/hooks/useFormat';
import FormattedAddress from '../../checkout-address-summary/formatted-address';
import FormattedDataPair from '../formatted-data-pair';

interface DeliveryDetailsProps extends HTMLAttributes<HTMLDivElement> {
  order: Order | Cart;
  shippingMethod: ShippingMethod;
}

export default function DeliveryDetails({
  className,
  order,
  shippingMethod,
  ...props
}: DeliveryDetailsProps): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const { shippingAddress } = order;
  const { locale } = useParams();
  const shipping_price = !shippingMethod?.name?.toUpperCase().includes('NOTREADY')
    ? `${CurrencyHelpers.formatForCurrency(
        shippingMethod?.rates?.[0].price || 0,
        locale,
        undefined,
        undefined,
        formatMessage({ id: 'tastics.checkout.shipping.shipping-content-manager.free' }),
      )} — `
    : '';
  const isOrder = (x: Order | Cart): x is Order => typeof (x as Order).orderNumber !== 'undefined';

  return (
    <section className={clsx('flex w-full flex-col gap-y-4', className)} {...props}>
      <header className="flex flex-col gap-y-2 lg:mt-4 lg:flex-row lg:items-center">
        <h2 className="font-sans text-20 font-semibold">
          {formatMessage({ id: 'components.checkout-order-confirmation.delivery_details' })}
        </h2>
      </header>

      <div className="surface-grey-1 grid p-4">
        <div className="grid gap-2 p-4">
          <FormattedDataPair
            label={formatMessage({ id: 'tastics.account.orders-content-manager.order_number' })}
            value={isOrder(order) && order.orderNumber ? order.orderNumber : '-'}
          />
        </div>
        <div className="grid gap-2 border-t-2 border-solid border-white p-4">
          <div className="flex flex-col gap-y-1">
            <FormattedDataPair
              label={shippingMethod.custom.uiName}
              value={shipping_price + ' ' + shippingMethod.custom.uiDescription}
            />
          </div>
        </div>
        <div className="grid gap-2 border-t-2 border-solid border-white p-4">
          <div className="flex flex-col gap-y-1">
            <FormattedDataPair
              label={formatMessage({ id: 'tastics.account.order-detail-content-manager.delivery_date' })}
              value={
                shippingMethod.name == 'IS-STD-NOTREADY' || !order.custom || !order.custom.deliveryDate ? (
                  <span>{formatMessage({ id: 'components.checkout-order-confirmation.not_yet_ready_delivery' })}</span>
                ) : (
                  <span className=" surface-grey-5 w-fit rounded-md px-3 py-2 font-bold" data-testid="delivery-date">
                    {format(order.custom.deliveryDate, 'EEEE, dd LLLL yyyy')}
                  </span>
                )
              }
            />
          </div>
        </div>

        <div className="grid gap-2 border-t-2 border-solid border-white p-4">
          <p className="font-sans text-14 font-semibold">
            {formatMessage({ id: 'tastics.account.order-detail-content-manager.delivery_address' })}
          </p>
          <FormattedAddress
            className="flex flex-col gap-y-2 text-14 text-grey-4"
            type="shippingAddress"
            cart={order as Cart}
          />
        </div>
        <div className="grid gap-2 border-t-2 border-solid border-white p-4">
          <FormattedDataPair
            label={formatMessage({ id: 'components.checkout-address-summary.email' })}
            value={order.email as string}
          />
        </div>
        <div className="grid gap-2 border-t-2 border-solid border-white p-4">
          <FormattedDataPair
            label={formatMessage({ id: 'components.checkout-address-summary.contact' })}
            value={shippingAddress?.phone as string}
          />
        </div>
      </div>
    </section>
  );
}
