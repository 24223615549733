import OrderConfirmationFailure from './failure';
import OrderConfirmationHeld from './held';
import OrderConfirmationThankYou from './thank-you';

export default function HeaderMessage({ paymentStatus }: { paymentStatus: string }) {
  switch (paymentStatus) {
    case 'pending': // page title: Order Held
      return <OrderConfirmationHeld />;
    case 'paid': // page title: Thank You
      return <OrderConfirmationThankYou />;
    case 'failed': // todo: page title: Order Failed
      return <OrderConfirmationFailure />;
    case 'init':
      // error 403 Forbidden (or 402 Payment Required experimental)
      return '';
    default:
      return '';
  }
}
