import Digicert from '~/components/furniturechoice/digicert';
import { useFormat } from '~/helpers/hooks/useFormat';

export default function OrderConfirmationHeld() {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <>
      <h1 className="font-sans text-24 font-bold">
        {formatMessage({ id: 'components.checkout-order-confirmation.order_held' })}{' '}
        <Digicert className="float-right ml-auto pt-2" />
      </h1>
      <p>{formatMessage({ id: 'components.checkout-order-confirmation.put_on_hold_for_review' })}</p>
      <p>{formatMessage({ id: 'components.checkout-order-confirmation.order_held_reason' })}</p>
      <p>{formatMessage({ id: 'components.checkout-order-confirmation.no_delivery_date' })}</p>
    </>
  );
}
