'use client';

import { HTMLAttributes } from 'react';
import { useParams } from 'next/navigation';
import { Cart, Order, ShippingMethod } from 'shared/types/cart';
import CartTotal from '~/components/furniturechoice/cart-pricing/CartTotal';
import FNCLinkButton from '~/components/furniturechoice/fnc/fnc-link-button';
import { CurrencyHelpers } from '~/helpers/currencyHelpers';
import { getPriceSummary } from '~/helpers/furniturechoice/displayPriceHelper';
import { useFormat } from '~/helpers/hooks/useFormat';
import LineItem from '../../checkout-order-summary/line-item';

interface OrderSummaryProps extends HTMLAttributes<HTMLDivElement> {
  order: Order | Cart;
  shippingMethod?: ShippingMethod;
}

export default function OrderSummary({ order, shippingMethod, ...props }: OrderSummaryProps): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const { locale } = useParams();
  const { taxAmount, subtotalAmount, discountAmount } = getPriceSummary(order as Cart);

  const totalLineItemQuantity = order.lineItems?.reduce(
    (accumulator, currentValue) => accumulator + (currentValue.count as number),
    0,
  );

  return (
    <section className="flex flex-col gap-y-5" {...props}>
      <header className="flex">
        <div className="flex gap-x-2">
          <h2 className="font-sans text-20 font-bold">
            {formatMessage({ id: 'components.checkout-order-summary.order_summary' })}
          </h2>

          <p className="mt-auto text-14 leading-none text-grey-7">
            {`(${totalLineItemQuantity} ${formatMessage({
              id: 'components.checkout-order-summary.items',
            })})`}
          </p>
        </div>
      </header>
      <div className="surface-grey-1 flex flex-col gap-y-8 rounded-md px-4 pb-4 pt-8 lg:px-8">
        <div className="flex flex-col gap-y-4 lg:gap-y-8">
          {order.lineItems?.map((lineItem) => (
            <LineItem key={lineItem.lineItemId} lineItem={lineItem} />
          ))}
        </div>

        <div className="py-5">
          <table className="w-full">
            <thead className="sr-only">
              <tr>
                <th>{formatMessage({ id: 'components.checkout-order-summary.description' })}</th>
                <th>{formatMessage({ id: 'components.checkout-order-summary.price' })}</th>
              </tr>
            </thead>

            <tbody className="text-16">
              <tr className="group border-t-2 border-solid border-white text-grey-5 ">
                <td className="py-3 pr-4 group-[:nth-last-child(2)]:pb-5">
                  {formatMessage({ id: 'components.checkout-order-summary.order_subtotal' })}
                </td>

                <td className="py-3 text-right group-[:nth-last-child(2)]:pb-5">
                  {CurrencyHelpers.formatForCurrency(
                    subtotalAmount ?? 0,
                    locale,
                    order.sum?.currencyCode,
                    order.sum?.fractionDigits,
                  )}
                </td>
                {/* <td className="p-0 text-right"></td> */}
              </tr>

              {discountAmount > 0 && (
                <tr className="group border-t-2 border-solid border-white text-grey-5">
                  <td className="py-3 pr-4 group-[:nth-last-child(2)]:pb-5">
                    {formatMessage({ id: 'components.checkout-order-confirmation.discount' })}
                  </td>
                  <td className="py-3 text-right group-[:nth-last-child(2)]:pb-5">
                    {CurrencyHelpers.formatForCurrency(
                      discountAmount ?? 0,
                      locale,
                      order.sum?.currencyCode,
                      order.sum?.fractionDigits,
                    )}
                  </td>
                </tr>
              )}

              {order.shippingInfo?.price && (
                <tr className="group border-t-2 border-solid border-white text-grey-5">
                  <td className="py-3 pr-4 group-[:nth-last-child(2)]:pb-5">
                    {shippingMethod && shippingMethod.name != 'IS-STD-NOTREADY'
                      ? shippingMethod?.custom.uiName
                      : formatMessage({ id: 'components.checkout-order-summary.delivery' })}
                  </td>

                  <td className="py-3 text-right group-[:nth-last-child(2)]:pb-5">
                    {CurrencyHelpers.formatForCurrency(order.shippingInfo.price ?? 0, locale)}
                  </td>
                </tr>
              )}

              <tr className="group border-t-2 border-solid border-white">
                <td className="py-3 pr-4 group-[:nth-last-child(2)]:pb-5">
                  {formatMessage({ id: 'components.checkout-order-summary.total_vat' })}
                </td>
                <td className="py-3 text-right group-[:nth-last-child(2)]:pb-5">
                  {CurrencyHelpers.formatForCurrency(
                    taxAmount ?? 0,
                    locale,
                    order.sum?.currencyCode,
                    order.sum?.fractionDigits,
                  )}
                </td>
              </tr>

              <tr className="border-t-2 border-solid border-white">
                <td className="p-0 pr-4 pt-5 align-top">
                  <span className="text-20 font-bold">
                    {formatMessage({ id: 'components.checkout-order-summary.total' })}
                  </span>{' '}
                  <span className="mt-2 text-14 text-grey-5">
                    ({formatMessage({ id: 'components.checkout-order-summary.including_vat' })})
                  </span>
                </td>
                <td className="p-0 pt-5 text-right">
                  <CartTotal className="text-20 font-bold" cart={order as Cart} locale={locale} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="hidden lg:block">
        <FNCLinkButton href="/" className="surface-salmon-1 mx-auto text-16 font-normal">
          {formatMessage({
            id: 'tastics.cart.cart-content-manager.continue_shopping',
            defaultMessage: 'Continue shopping',
          })}
        </FNCLinkButton>
      </div>
    </section>
  );
}
