import Digicert from '~/components/furniturechoice/digicert';
import { useFormat } from '~/helpers/hooks/useFormat';

export default function OrderConfirmationThankYou() {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <>
      <h1 className="font-sans text-24 font-bold">
        {formatMessage({ id: 'components.checkout-order-confirmation.thank_you_for_your_order' })}{' '}
        <Digicert className="float-right ml-auto pt-2" />
      </h1>
      <p>{formatMessage({ id: 'components.checkout-order-confirmation.confirmation_email_will_arive_shortly' })}</p>
      <p>
        {formatMessage({ id: 'components.checkout-order-confirmation.if_you_dont_receive' })}{' '}
        <a href={`tel:${process.env.NEXT_PUBLIC_FNC_TELEPHONE_NUMBER_ACTUAL}`} className="text-grey-5">
          {process.env.NEXT_PUBLIC_FNC_TELEPHONE_NUMBER_DISPLAY}
        </a>{' '}
        {formatMessage({ id: 'components.checkout-order-confirmation.and_we_will_resend' })}
      </p>
    </>
  );
}
