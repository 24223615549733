import React from 'react';

type Props = {
  className?: string;
}

const Icon: React.FC<Props> = ({ className } : Props) => 
  <svg className={className} width="19" height="23" viewBox="0 0 19 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.96667 6.6759e-05C6.0223 -0.00453309 5.09172 0.22864 4.25924 0.67846C3.42677 1.12828 2.71871 1.78053 2.19923 2.5761C1.67974 3.37168 1.36525 4.28544 1.28425 5.23459C1.20324 6.18374 1.35829 7.13827 1.73533 8.01171C0.633333 9.09782 0 10.58 0 12.1389C0 15.3334 2.53333 17.8889 5.7 17.8889C6.33333 17.8889 6.96667 17.7483 7.6 17.5311V23H10.1333V16.3172C10.7667 16.4961 11.4 16.6111 12.0333 16.6111C12.9482 16.6111 13.8541 16.4294 14.6994 16.0762C15.5446 15.723 16.3126 15.2053 16.9595 14.5527C17.6064 13.9002 18.1196 13.1254 18.4697 12.2728C18.8198 11.4201 19 10.5063 19 9.58337C19 8.66047 18.8198 7.74661 18.4697 6.89397C18.1196 6.04132 17.6064 5.26659 16.9595 4.614C16.3126 3.96141 15.5446 3.44375 14.6994 3.09057C13.8541 2.73739 12.9482 2.55561 12.0333 2.55561H11.704C10.6527 0.971175 8.86667 6.6759e-05 6.96667 6.6759e-05ZM6.96667 2.55561C8.63867 2.55561 10.0193 3.87172 10.1333 5.55838C10.716 5.2645 11.4 5.11116 12.0333 5.11116C13.2091 5.11116 14.3368 5.58234 15.1682 6.42104C15.9996 7.25975 16.4667 8.39727 16.4667 9.58337C16.4667 10.7695 15.9996 11.907 15.1682 12.7457C14.3368 13.5844 13.2091 14.0556 12.0333 14.0556C10.8173 14.0556 9.66467 13.5573 8.816 12.6628C8.69541 13.4086 8.31571 14.0868 7.74482 14.5761C7.17393 15.0654 6.44911 15.3338 5.7 15.3334C4.86015 15.3334 4.05469 14.9968 3.46083 14.3977C2.86696 13.7987 2.53333 12.9861 2.53333 12.1389C2.53333 10.3756 3.54667 9.63448 5.06667 8.67615C4.05333 7.36005 3.8 6.59338 3.8 5.75005C3.8 4.90283 4.13363 4.09032 4.7275 3.49124C5.32136 2.89217 6.12681 2.55561 6.96667 2.55561Z" fill="#64B43F" />
  </svg>

export default Icon;
