import { HTMLAttributes } from 'react';
import { Address } from 'shared/types/account';
import { Cart } from 'shared/types/cart';
import { billingCountries } from '~/static/billing-countries';

interface FormattedAddressProps extends HTMLAttributes<HTMLDivElement> {
  type: 'billingAddress' | 'shippingAddress';
  cart: Cart;
}

function buildAddress(address: Address): string {
  const keys = ['streetNumber', 'streetName', 'additionalAddressInfo', 'city', 'region', 'postalCode'];
  const values = keys.map((key) => address[key as keyof Address]);

  return [...values, billingCountries[address.country as keyof typeof billingCountries]].filter((i) => i).join('\n');
}

export default function FormattedAddress({ type, cart, className }: FormattedAddressProps): JSX.Element {
  const address = cart[type];

  if (!className) className = 'flex flex-col gap-y-2 pl-8 text-14';

  return (
    <div className={className}>
      <p className="font-semibold" data-testid="name">
        {`${address?.salutation} ${address?.firstName} ${address?.lastName}`}
      </p>

      <p className="whitespace-pre-line leading-5 text-grey-5" data-testid="address">
        {buildAddress(address as Address)}
      </p>
    </div>
  );
}
