// TODO: this is temporary

import React from 'react';
//import Script from 'next/script';
import DigicertIcon from '~/components/icons/digicert';

export default function Digicert({ className }: { className?: string }): JSX.Element {
  // return <><div id="DigiCertClickID_bB_3FvcV"></div>
  //<Script>
  //	var __dcid = __dcid || [];
  //	__dcid.push({"cid":"DigiCertClickID_bB_3FvcV","tag":"bB_3FvcV"});
  //	(function(){var cid=document.createElement("script");cid.async=true;cid.src="//seal.digicert.com/seals/cascade/seal.min.js";var s = document.getElementsByTagName("script");var ls = s[(s.length - 1)];ls.parentNode.insertBefore(cid, ls.nextSibling);}());
  //</Script></>;

  return <DigicertIcon className={className} />;
}
