import { MdMailOutline, MdPhoneIphone, MdOutlineChat } from 'react-icons/md';
import InstagramSVG from '~/components/furniturechoice/svg/Instagram.svg';
import { useFormat } from '~/helpers/hooks/useFormat';
import FNCLinkButton from '../../fnc/fnc-link-button';
import FNCSmallLinkButton from '../../fnc/fnc-small-link-button';
import Link from '../../link';

export default function RealCustomersRealHomes() {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <section className=" text-grey-5 lg:mt-10">
      <div className="grid gap-5 font-normal">
        <div className="flex gap-2 text-20 font-semibold leading-6 text-grey-6">
          <div className="inline-block w-6">
            <InstagramSVG />
          </div>
          <div>{formatMessage({ id: 'components.checkout-order-confirmation.real_customers_real_homes' })}</div>
        </div>
        <p>{formatMessage({ id: 'components.checkout-order-confirmation.wed_love_to_share' })}</p>
        <p>
          {formatMessage({ id: 'components.checkout-order-confirmation.tag_us_on_instagram' })}{' '}
          <Link className="text-salmon-1" href="https://www.instagram.com/furnitureandchoice/">
            @furnitureandchoice
          </Link>
        </p>
        <div className="lg:hidden">
          <FNCLinkButton href="/" className="surface-salmon-1 mx-auto text-16 font-normal">
            {formatMessage({
              id: 'tastics.cart.cart-content-manager.continue_shopping',
              defaultMessage: 'Continue shopping',
            })}
          </FNCLinkButton>
        </div>
        <p></p>
        <p>
          <span className="font-semibold">
            {formatMessage({ id: 'components.checkout-order-confirmation.still_have_questions' })}
          </span>{' '}
          <span>{formatMessage({ id: 'components.checkout-order-confirmation.were_happy_to_help' })}</span>
        </p>
        <div className="hidden grid-cols-3 gap-4 text-12 sm:grid">
          <FNCLinkButton
            href={'tel:' + process.env.NEXT_PUBLIC_FNC_TELEPHONE_NUMBER_ACTUAL}
            className="bg-black font-light text-white"
            icon={<MdPhoneIphone />}
          >
            {formatMessage({ id: 'components.checkout-order-confirmation.phone' })}
          </FNCLinkButton>
          <FNCLinkButton
            href="javascript: if (typeof zE !== 'undefined') if (typeof zE.activate !== 'undefined') zE.activate(); else zE('messenger', 'open');"
            className="bg-black font-light text-white"
            icon={<MdOutlineChat />}
          >
            {formatMessage({ id: 'components.checkout-order-confirmation.live_chat' })}
          </FNCLinkButton>
          <FNCLinkButton
            href={'mailto:' + process.env.NEXT_PUBLIC_FNC_EMAIL}
            className="bg-black font-light text-white"
            icon={<MdMailOutline />}
          >
            {formatMessage({ id: 'components.checkout-order-confirmation.email' })}
          </FNCLinkButton>
        </div>
        <div className="grid grid-cols-3 gap-4 text-14 sm:hidden">
          <div className="grid gap-1 text-center">
            <FNCSmallLinkButton
              href={'tel:' + process.env.NEXT_PUBLIC_FNC_TELEPHONE_NUMBER_ACTUAL}
              className="bg-black text-center text-28 text-white"
              icon={<MdPhoneIphone />}
            >
              {formatMessage({ id: 'components.checkout-order-confirmation.phone' })}
            </FNCSmallLinkButton>
          </div>
          <div className="grid gap-1 text-center">
            <FNCSmallLinkButton
              href="javascript: if (typeof zE !== 'undefined') if (typeof zE.activate !== 'undefined') zE.activate(); else zE('messenger', 'open');"
              className="bg-black text-center text-28 text-white"
              icon={<MdOutlineChat />}
            >
              {formatMessage({ id: 'components.checkout-order-confirmation.live_chat' })}
            </FNCSmallLinkButton>
          </div>
          <div className="grid gap-1 text-center">
            <FNCSmallLinkButton
              href={'mailto:' + process.env.NEXT_PUBLIC_FNC_EMAIL}
              className="bg-black text-center text-28 text-white"
              icon={<MdMailOutline />}
            >
              {formatMessage({ id: 'components.checkout-order-confirmation.email' })}
            </FNCSmallLinkButton>
          </div>
        </div>
      </div>
    </section>
  );
}
