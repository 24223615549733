'use client';

import { Cart, Order, ShippingMethod } from 'shared/types/cart';
import { Payment } from 'shared/types/cart/Payment';
import BillingDetails from '~/components/furniturechoice/order-confirmation/billing-details';
import DeliveryDetails from '~/components/furniturechoice/order-confirmation/delivery-details';
import OrderSummary from '~/components/furniturechoice/order-confirmation/order-summary';
import RealCustomersRealHomes from '~/components/furniturechoice/order-confirmation/real-customers-real-homes';
import { TasticProps } from '~/frontastic/tastics/types';
import { DataSourceV2 } from '~/types/datasource';
import ActionBar from './ActionBar';
import EcologiMessage from './EcologiMessage';
import HeaderMessage from './HeaderMessage';
import Steps from '../checkout-header/steps';

interface OrderConfirmationProps {
  payment: DataSourceV2<{
    payment: Payment;
    order: Order;
    cart?: Cart;
    shipping: ShippingMethod;
    ok?: boolean;
    message?: string;
  }>;
}

export default function OrderConfirmation({
  data: {
    payment: { dataSource },
  },
}: TasticProps<OrderConfirmationProps>) {
  // be sure that a valid payment was found
  if (!dataSource.payment || dataSource.ok === false) {
    if (dataSource.payment) {
      console.log(dataSource.message);
    } else {
      console.log('Payment not found');
    }
    return <div></div>;
  }

  // should not be able to reach this page if payment is still processing
  if (dataSource.payment.paymentStatus == 'init' || !dataSource.payment.paymentStatus) {
    console.log('Payment not completed');
    return <div></div>;
  }

  // no need to show summary if payment failed
  if (dataSource.payment.paymentStatus == 'failed') {
    return (
      <div className="py-8">
        <HeaderMessage paymentStatus="failed" />
      </div>
    );
  }

  // success or referred
  return (
    <div className="py-8">
      <div className="container grid gap-4 text-center">
        <HeaderMessage paymentStatus={dataSource.payment.paymentStatus} />
        <EcologiMessage />
        <div className="py-6">
          <Steps currentStep={4} />
        </div>
      </div>
      <ActionBar />
      <div className="container grid gap-y-8">
        <div className="grid gap-8 lg:grid-cols-2">
          <DeliveryDetails order={dataSource.order || dataSource.cart} shippingMethod={dataSource.shipping} />
          <BillingDetails order={dataSource.order || dataSource.cart} payment={dataSource.payment} />
        </div>
        <div className="grid gap-8 lg:grid-cols-[auto_390px]">
          <OrderSummary order={dataSource.order || dataSource.cart} shippingMethod={dataSource.shipping} />
          <RealCustomersRealHomes />
        </div>
      </div>
    </div>
  );
}
