'use client';

import clsx from 'clsx';
import { MdCheck } from 'react-icons/md';

interface StepProps {
  step: {
    name: string;
    value: number;
  };
  currentStep: 1 | 2 | 3 | 4;
}

export default function Step({ step, currentStep }: StepProps) {
  return (
    <div className="flex flex-1 flex-col items-center gap-y-3">
      <div className="relative flex w-full items-center justify-center">
        {step.value !== 1 && (
          <div className="absolute left-0 right-1/2 h-0.5 bg-grey-9" data-testid="left-line-effect" />
        )}

        <div
          className={clsx('z-10 flex h-5 w-5 items-center justify-center rounded-full', {
            'surface-green-2 outline outline-[3px] outline-white': currentStep >= step.value,
          })}
          data-testid="state-container"
        >
          {currentStep > step.value && <MdCheck className="text-18" data-testid="done-state" />}

          {currentStep === step.value && (
            <div className="h-1.5 w-1.5 rounded-full bg-white" data-testid="current-state" />
          )}

          {currentStep < step.value && <div className="h-3 w-3 rounded-full bg-grey-9" data-testid="next-state" />}
        </div>

        {step.value !== 4 && (
          <div className="absolute left-1/2 right-0 h-0.5 bg-grey-9" data-testid="right-line-effect" />
        )}
      </div>

      <p className="px-2 text-center text-12 leading-[15px] text-grey-5">{step.name}</p>
    </div>
  );
}
