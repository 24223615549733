import { MdPersonOutline } from 'react-icons/md';
import Link from '~/components/furniturechoice/link';
import PrintButton from '~/components/furniturechoice/print-button';
import { useFormat } from '~/helpers/hooks/useFormat';

export default function ActionBar() {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  return (
    <div className="container flex justify-start gap-2 py-4 lg:justify-end">
      <div>
        <PrintButton
          text={formatMessage({ id: 'components.checkout-order-confirmation.print' })}
          className="flex items-center gap-x-1 text-14 text-grey-4"
        />
      </div>
      <div>
        <Link className="flex items-center gap-x-1 text-14 text-grey-4" href="/my-account">
          <MdPersonOutline className="text-16" />{' '}
          {formatMessage({ id: 'components.account-welcome-header.my_account' })}
        </Link>
      </div>
    </div>
  );
}
