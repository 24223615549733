interface FormattedDataPairProps {
  label: string;
  value: string | JSX.Element;
}

export default function FormattedDataPair({ label, value }: FormattedDataPairProps): JSX.Element {
  return (
    <div className="flex gap-x-2">
      <div>
        <p className="text-14 font-semibold leading-5">{label}</p>
        <p className="text-14 leading-5 text-grey-4">{value}</p>
      </div>
    </div>
  );
}
