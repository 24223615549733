import Digicert from '~/components/furniturechoice/digicert';
import FNCLinkButton from '~/components/furniturechoice/fnc/fnc-link-button';
import { useFormat } from '~/helpers/hooks/useFormat';

export default function OrderConfirmationFailure() {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <div className="container grid gap-4 text-center">
      <h1 className="font-sans text-24 font-bold">
        {formatMessage({
          id: 'components.checkout-order-confirmation.order_declined',
        })}
        <Digicert className="float-right ml-auto pt-2" />
      </h1>
      <p>
        {formatMessage({
          id: 'components.checkout-order-confirmation.sorry_your_payment_has_been_declined',
        })}
      </p>
      <p>
        {formatMessage({
          id: 'components.checkout-order-confirmation.please_contact_your_payment_provider',
        })}
      </p>
      <FNCLinkButton href="/" className="surface-salmon-1 mx-auto text-16 font-normal">
        {formatMessage({
          id: 'tastics.cart.cart-content-manager.continue_shopping',
          defaultMessage: 'Continue shopping',
        })}
      </FNCLinkButton>
    </div>
  );
}
