// todo: implement real print
import { type InputHTMLAttributes } from 'react';
import { MdPrint } from 'react-icons/md';

interface PrintButtonProps extends InputHTMLAttributes<HTMLButtonElement> {
  text: string;
}

export default function PrintButton({ text, className }: PrintButtonProps) {
  const clickHandler = () => {
    window.print();
  };

  return (
    <button onClick={clickHandler} className={className} type="button">
      <MdPrint /> {text}
    </button>
  );
}
