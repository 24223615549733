import { ComponentProps } from 'react';
import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';
import Link from '~/components/furniturechoice/link';

interface FNCSmallLinkButtonProps extends ComponentProps<typeof Link> {
  disabled?: boolean;
  icon: JSX.Element;
}

export default function FNCSmallLinkButton({
  className,
  children,
  reference,
  disabled,
  icon,
  ...props
}: FNCSmallLinkButtonProps): JSX.Element {
  return (
    <Link
      className={twMerge(
        clsx('grid min-h-[40px] grid-cols-1 gap-2 text-center font-semibold', {
          'pointer-events-none opacity-[0.38]': disabled,
          'hover:brightness-90': !disabled,
        }),
      )}
      reference={reference}
      {...props}
    >
      <div
        className={clsx(
          'min-w-[60px] items-center justify-center rounded-md px-6 py-2 [&_svg]:inline-block [&_svg]:text-30',
          className,
        )}
      >
        {icon}
      </div>
      {children && <div className="font-semibold text-black">{children}</div>}
    </Link>
  );
}
