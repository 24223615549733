import EcologiIcon from '~/components/icons/ecologi-tree';
import { useFormat } from '~/helpers/hooks/useFormat';

export default function EcologiMessage() {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <>
      <div className="surface-grey-1 p-4 font-normal">
        <EcologiIcon className="mr-2 inline-block" />
        {formatMessage({ id: 'components.checkout-order-confirmation.ecologi_message' })}
      </div>
    </>
  );
}
