'use client';

import { useFormat } from '~/helpers/hooks/useFormat';
import Step from './step';

interface StepsProps {
  currentStep: 1 | 2 | 3 | 4;
}

export default function Steps({ currentStep }: StepsProps) {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const steps = [
    { value: 1, name: formatMessage({ id: 'tastics.checkout.checkout-header.contact_details' }) },
    { value: 2, name: formatMessage({ id: 'tastics.checkout.checkout-header.select_delivery_dates' }) },
    { value: 3, name: formatMessage({ id: 'tastics.checkout.checkout-header.payment' }) },
    { value: 4, name: formatMessage({ id: 'tastics.checkout.checkout-header.confirmation' }) },
  ];

  return (
    <div className="mx-auto flex max-w-[628px]">
      {steps.map((step) => (
        <Step key={step.value} step={step} currentStep={currentStep} />
      ))}
    </div>
  );
}
