import { HTMLAttributes } from 'react';
import { useParams } from 'next/navigation';
import clsx from 'clsx';
import { format } from 'date-fns';
import { Order, Cart, Payment } from 'shared/types/cart';
import { CurrencyHelpers } from '~/helpers/currencyHelpers';
import { useFormat } from '~/helpers/hooks/useFormat';
import FormattedAddress from '../../checkout-address-summary/formatted-address';
import FormattedDataPair from '../formatted-data-pair';

interface BillingDetailsProps extends HTMLAttributes<HTMLDivElement> {
  order: Order | Cart;
  payment: Payment;
}

export default function BillingDetails({ className, order, payment, ...props }: BillingDetailsProps): JSX.Element {
  const { locale } = useParams();
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const { billingAddress } = order;
  const isOrder = (x: Order | Cart): x is Order => typeof (x as Order).orderNumber !== 'undefined';

  return (
    <section className={clsx('flex w-full flex-col gap-y-4', className)} {...props}>
      <header className="flex flex-col gap-y-2 lg:mt-4 lg:flex-row lg:items-center">
        <h2 className="font-sans text-20 font-semibold">
          {formatMessage({ id: 'components.checkout-order-confirmation.billing_details' })}
        </h2>
      </header>
      <div className="surface-grey-1 grid p-4">
        <div className="grid gap-2 p-4">
          <FormattedDataPair
            label="Transaction date"
            value={isOrder(order) ? format(order.createdAt as Date, 'dd/LL/yyyy') : '-'}
          />
        </div>
        <div className="grid gap-2 border-t-2 border-solid border-white p-4">
          <h3 className="font-sans text-14 font-semibold">
            {formatMessage({ id: 'tastics.account.order-detail-content-manager.billing_address' })}
          </h3>
          <FormattedAddress
            className="flex flex-col gap-y-2 text-14 text-grey-4"
            type="billingAddress"
            cart={order as Cart}
          />
        </div>

        <div className="grid gap-2 border-t-2 border-solid border-white p-4">
          <FormattedDataPair
            label={formatMessage({ id: 'components.checkout-address-summary.email' })}
            value={order.email as string}
          />
        </div>
        <div className="grid gap-2 border-t-2 border-solid border-white p-4">
          <FormattedDataPair
            label={formatMessage({ id: 'components.checkout-address-summary.contact' })}
            value={billingAddress?.phone as string}
          />
        </div>
        <div className="grid gap-2 border-t-2 border-solid border-white p-4">
          <FormattedDataPair
            label="Payment details"
            value={
              formatMessage({ id: 'components.payment-method.' + payment.paymentProvider }) +
              ' - ' +
              CurrencyHelpers.formatForCurrency(
                payment.amountPlanned.centAmount as number,
                locale,
                payment.amountPlanned.currencyCode,
                payment.amountPlanned.fractionDigits,
              )
            }
          />
        </div>
      </div>
    </section>
  );
}
